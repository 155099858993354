import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

export function ButtonLink({
  className = "",
  link = "/",
  externalLink = false,
  hoverEffect = "black",
  buttonText = "Click",
}) {
  const buttonHoverClass = classNames({
    "black-shape": hoverEffect === "black",
    "hover-shape": hoverEffect === "hover",
  });

  return !externalLink ? (
    <Link className={`${buttonHoverClass} ${className}`} to={link}>
      <span className="btn-text">{buttonText}</span>
      <span className="hover-shape1"></span>
      <span className="hover-shape2"></span>
      <span className="hover-shape3"></span>
    </Link>
  ) : (
    <a className={` ${buttonHoverClass} ${className}`} href={link}>
      <span className="btn-text">{buttonText}</span>
      <span className="hover-shape1"></span>
      <span className="hover-shape2"></span>
      <span className="hover-shape3"></span>
    </a>
  );
}

export function Button({
  IsButtonDisabled = false,
  className = "",
  hoverEffect = "black",
  buttonText = "Click",
  onButtonClicked,
}) {
  const buttonHoverClass = classNames({
    "black-shape": hoverEffect === "black",
    "hover-shape": hoverEffect === "hover",
    readon: hoverEffect === "readon",
  });

  return (
    <button
      disabled={IsButtonDisabled}
      className={`${buttonHoverClass} ${className}`}
      onClick={onButtonClicked}
    >
      <span className="btn-text">{buttonText}</span>
      <span className="hover-shape1"></span>
      <span className="hover-shape2"></span>
      <span className="hover-shape3"></span>
    </button>
  );
}
