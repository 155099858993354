import React, { useState, useEffect } from "react";
import classNames from "classnames";

import "./index.scss";

export function Popup({
  isOpen = false,
  info = false,
  warning = false,
  error = false,
  secondary = false,
  title,
  message,
  onButtonConfirm,
  onModalClosed,
}) {
  const [modalOpen, setModalOpen] = useState(isOpen);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen, message]);

  const popupContainerBackground = classNames({
    "popup__background--transparent": true,
  });

  const popupContainerClass = classNames({
    "popup__container--violet": info,
    "popup__container--orange": warning,
    "popup__container--red": error,
    "popup__container--gray": secondary,
    "popup__container--violet": !info && !warning && !error,
  });

  const headerBorderClass = classNames({
    "popup__header--violet": info,
    "popup__header--orange": warning,
    "popup__header--red": error,
    "popup__header--white": title,
  });

  const buttonClass = classNames({
    "popup__button--violet": info,
    "popup__button--orange": warning,
    "popup__button--red": error,
    "popup__button--violet": title || message,
  });

  const _popupMessage = (message) => {
    if (typeof message === "string") return message;
    return "";
  };

  return !modalOpen ? null : (
    <div className={`popup popup__background ${popupContainerBackground}`}>
      <div className={`popup__container ${popupContainerClass}`}>
        <div className="popup__content">
          <div className={`popup__header ${headerBorderClass}`}>{title}</div>
          <div className={`popup__description `}>{_popupMessage(message)}</div>
        </div>
        <div className={`popup__action`}>
          {!onButtonConfirm ? null : (
            <button
              className={`popup__button ${buttonClass}`}
              onClick={() => {
                onButtonConfirm();
              }}
            >
              Confirm
            </button>
          )}

          <button
            className={`popup__button ${buttonClass}`}
            onClick={() => {
              if (onModalClosed) {
                onModalClosed();
              }
              setModalOpen(!modalOpen);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export function PopupModal({ isOpen = false, children }) {
  const [modalOpen, setModalOpen] = useState(isOpen);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const popupContainerBackground = classNames({
    "popup__background--transparent": true,
  });

  return !modalOpen ? null : (
    <div className={`popup popup__background ${popupContainerBackground}`}>
      <div className={`popup__modal`}>{children}</div>
    </div>
  );
}
