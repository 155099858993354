import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import RoutePaths from "../../routes/routhPaths";

import NLessLogo from "../../shared/images/logo-burn.png";
import ConnectLogo from "../../shared/images/icons/connect.png";
import WalletIcon from "../../shared/images/icons/connect_white.png";

export default function Header({
  activeUser,
  showWaxModal,
  headerStyle = "default",
  onUserLogout,
}) {
  const { home, project } = RoutePaths;

  const headerStyleClass = classNames({
    "transparent-header": headerStyle === "transparent",
    "default-header": headerStyle === "default",
  });

  const _setNavExpanded = (isExpanded = false) => {
    if (isExpanded) {
      document.body.classList.add("nav-expanded");
      return;
    }
    document.body.classList.remove("nav-expanded");
  };

  return (
    <header
      id="gamfi-header"
      className={`gamfi-header-section ${headerStyleClass}`}
    >
      <div className="menu-area menu-sticky">
        <div className="container">
          <div className="heaader-inner-area d-flex justify-content-between align-items-center">
            <div className="gamfi-logo-area d-flex justify-content-between align-items-center">
              <div className="logo">
                <a href="https://www.nlessft.io/">
                  <img src={NLessLogo} alt="logo" width="140" />
                </a>
              </div>
              <div className="header-menu">
                <ul className="nav-menu">
                  <li>
                    <Link to={home}>Home</Link>
                  </li>
                  <li>
                    <Link to={project}>Reward Pools</Link>
                  </li>
                  <li>
                    <a href="https://top.nlessft.io">Competitions</a>
                  </li>
                  <li>
                    <Link to={home}>Guide</Link>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdQQiWUiaGz_tbPf5toJcaLHUWD4YbXxlUYaQKj3tWWK7pcow/viewform"
                    >
                      Apply
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="gamfi-btn-area">
              <ul>
                <li>
                  <a
                    id="nav-expander"
                    className="nav-expander bar"
                    rel="noreferrer"
                    onClick={() => {
                      _setNavExpanded(true);
                    }}
                  >
                    <div className="bar">
                      <span className="dot1"></span>
                      <span className="dot2"></span>
                      <span className="dot3"></span>
                    </div>
                  </a>
                </li>
                <li className="buy-token">
                  <a
                    className="readon black-shape"
                    href={home}
                    rel="noreferrer"
                  >
                    <span className="btn-text">How Does It Work? </span>
                  </a>
                </li>
                <li>
                  {activeUser ? (
                    <button
                      className="readon white-btn hover-shape"
                      href={home}
                      rel="noreferrer"
                      style={{ marginLeft: "1em" }}
                      onClick={() => onUserLogout()}
                    >
                      <span className="btn-text">
                        {activeUser?.accountName}
                      </span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="readon white-btn hover-shape"
                      onClick={() => showWaxModal()}
                    >
                      <img src={ConnectLogo} alt="Icon" />
                      <span className="btn-text">Connect </span>
                      <span className="hover-shape1"></span>
                      <span className="hover-shape2"></span>
                      <span className="hover-shape3"></span>
                    </button>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <nav
        className="right_menu_togle mobile-navbar-menu"
        id="mobile-navbar-menu"
      >
        <div
          className="close-btn"
          onClick={() => {
            _setNavExpanded(false);
          }}
        >
          <span id="nav-close2" className="nav-close">
            <div className="line">
              <span className="line1"></span>
              <span className="line2"></span>
            </div>
          </span>
        </div>
        <div className="sidebar-logo mb-30">
          <a href="/">
            <img src={NLessLogo} alt="" />
          </a>
        </div>
        <ul className="nav-menu">
          <li className="current-menu-item">
            <Link
              to={home}
              onClick={() => {
                _setNavExpanded(false);
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to={project}
              onClick={() => {
                _setNavExpanded(false);
              }}
            >
              Reward Pools
            </Link>
          </li>
          <li>
            <a href="https://top.nlessft.io">Competitions</a>
          </li>
          <li>
            {activeUser ? (
              <button
                type="button"
                className="readon black-shape-big connectWalletBtnforMobile"
                onClick={() => onUserLogout()}
              >
                <span className="btn-text">{activeUser?.accountName}</span>
                <span className="hover-shape1"></span>
                <span className="hover-shape2"></span>
                <span className="hover-shape3"></span>
              </button>
            ) : (
              <button
                type="button"
                className="readon black-shape-big connectWalletBtnforMobile"
                onClick={() => showWaxModal()}
              >
                <img src={WalletIcon} alt="Icon" />
                <span className="btn-text">Connect </span>
                <span className="hover-shape1"></span>
                <span className="hover-shape2"></span>
                <span className="hover-shape3"></span>
              </button>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
}
