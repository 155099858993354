import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

export function ProjectDetailsRow({ className = "", children }) {
	return <ul className={`${className}`}>{children}</ul>;
}

export function ProjectDetailsColumn({
	columnNo = 1,
	className = "",
	linkClassName = "",
	value,
	valueLink = "/",
	valueLinkLabel = "",
	children,
}) {
	const projectDetailsColumnClass = classNames({
		column_1: columnNo === 1,
		column_2: columnNo === 2,
		column_3: columnNo === 3,
		column_4: columnNo === 4,
		column_5: columnNo === 5,
	});
	return (
		<li className={`${className} ${projectDetailsColumnClass}`}>
			{value}
			{!valueLink ? null : (
				<Link className={`${linkClassName}`} to={valueLink}>
					{valueLinkLabel}
				</Link>
			)}
			{children}
		</li>
	);
}
