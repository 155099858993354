import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import RoutePaths from "../../routes/routhPaths";

import {
  getPoolDetailRows,
  getPoolTemplates,
} from "../../services/PoolService";
import { getAccountAssetsByTemplate } from "../../services/AssetsService";
import transactionSigner from "../../services/TransactionSigner";

import Header from "../../components/Header/";
import Footer from "../../components/Footer";
import { GameBoxContainer } from "../../components/Container";
import { SimpleProjectItem } from "../../components/ProjectItem";
import { SimpleSectionHeader } from "../../components/Sections";
import {
  ProjectDetailsColumn,
  ProjectDetailsRow,
} from "../../components/TableSets";
import { Popup } from "../../components/Popup";

import { removeGnokenSuffix } from "../../utils/helpers";
import { getRewardsBalances } from "../../services/RewardService";

import StepsImage from "../../shared/images/icons/steps.png";

import "./index.scss";

export default function PoolDetailPage({
  activeUser,
  showWaxModal,
  onUserLogout,
}) {
  const parameters = useParams();
  const [loader, setLoader] = useState(true);

  const poolId = parameters?.id;
  const poolNumber = parseInt(poolId);
  const [userClaiming, setUserClaiming] = useState(false);
  const [userBurning, setUserBurning] = useState(false);
  const [userBalance, setUserBalance] = useState(null);
  const [poolDetails, setPoolDetails] = useState(null);
  const [poolTemplateRows, setPoolTemplateRows] = useState([]);
  const [templateAssetRows, setTemplateAssetRows] = useState([]);
  const [tokenQuantity, setTokenQuantity] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");

  const _getDailyRewards = (token, periodDays) => {
    return token && periodDays ? parseInt(token / periodDays).toFixed(2) : 0;
  };

  const _serviceInit = async () => {
    try {
      const rewardPools = await getPoolDetailRows();
      const poolTemplates = await getPoolTemplates(poolNumber);
      const rewardsBalances = await getRewardsBalances();

      if (rewardPools.success) {
        const details = rewardPools.responseData.rows.filter(
          (rp) => rp.id === parseInt(poolId)
        )[0];

        setPoolDetails(details);
        setTokenQuantity(parseInt(removeGnokenSuffix(details.token_quantity)));
      }

      if (poolTemplates.success) {
        let assets = [];
        const poolTemplateData = poolTemplates.responseData;

        poolTemplateData.forEach((pt) => {
          const _getAssetsByTemplateId = async () => {
            const response = await getAccountAssetsByTemplate(
              pt.template_id,
              activeUser.accountName
            );

            if (response.success) {
              assets = [...assets, ...response.responseData.data];
              setTemplateAssetRows(assets);
            }
          };

          _getAssetsByTemplateId();
        });

        setPoolTemplateRows(poolTemplateData);
      }

      if (rewardsBalances.success) {
        const balance = rewardsBalances.responseData.filter((rp) => {
          return (
            rp.account === activeUser.accountName &&
            rp.pool_id === parseInt(poolId)
          );
        })[0];
        setUserBalance(balance);
      }
    } catch (e) {
      console.log("🎶🎶🎶", e.message);
    }
  };

  useEffect(() => {
    _serviceInit();
    setTimeout(() => {
      setLoader(false);
    }, 600);
  }, [activeUser]);

  return (
    <div className="pool-detail-page">
      {!loader && poolId ? (
        <Fragment>
          <Popup
            isOpen={popupOpen}
            title={popupTitle}
            message={popupMessage}
            onModalClosed={() => setPopupOpen(false)}
          />

          <Header
            activeUser={activeUser}
            showWaxModal={showWaxModal}
            onUserLogout={onUserLogout}
          />

          <div className="gamfi-breadcrumbs-section breadcrumbs-style2">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="breadcrumbs-area sec-heading">
                    <div className="sub-inner">
                      <a className="breadcrumbs-link" href="index.html">
                        Home
                      </a>
                      <a className="breadcrumbs-link" href={RoutePaths.project}>
                        Pool List
                      </a>
                      <span className="sub-title">Pool Information</span>
                      <img
                        className="heading-left-image"
                        src={StepsImage}
                        alt="stepsImage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="project-details-conent gamfi-about-secion pb-80 md-pb-20">
            <div className="container">
              <GameBoxContainer
                progressBar
                bannerBottom
                bannerButton={activeUser && poolNumber}
                buttonDisabled={userClaiming}
                percentage={
                  userBalance?.quantity && tokenQuantity
                    ? (removeGnokenSuffix(userBalance.quantity) /
                        tokenQuantity) *
                      100
                    : 0
                }
                name={poolDetails?.token_contract}
                currency={poolDetails?.token_contract}
                description={`${tokenQuantity.toFixed(2)}`}
                allocationDetails={`Total Daily Rewards Distributing: ${tokenQuantity.toFixed(
                  2
                )}`}
                progressDetails={``}
                buttonText={userClaiming ? "Claiming..." : "Claim Daily Reward"}
                bannerLabel="YOUR DAILY REWARD BALANCE: "
                bannerDetails={
                  !userBalance?.quantity ? 0 : userBalance?.quantity
                }
                onButtonClicked={async () => {
                  setUserClaiming(true);
                  const transactionData = [
                    {
                      contractAccount: "endlessnftft",
                      actionName: "claimreward",
                      data: {
                        pool_id: poolId,
                        "name account": activeUser.accountName,
                      },
                    },
                  ];

                  const claimDailiesResponse = await transactionSigner(
                    activeUser,
                    transactionData
                  );

                  if (claimDailiesResponse.success) {
                    setPopupOpen(true);
                    setPopupTitle("Success");
                    setPopupMessage("Reward Successfully Claimed");
                  }

                  if (!claimDailiesResponse.success) {
                    setPopupOpen(true);
                    setPopupTitle("Failed");
                    setPopupMessage(claimDailiesResponse.responseData.message);
                  }
                  setUserClaiming(false);
                }}
              />

              <div className="row mt-30">
                <div className="col-md-6">
                  <SimpleProjectItem title={`Pool Information`} titleSteps>
                    <div className="project-media">
                      <ul className="project-listing">
                        <li>
                          Wallet Requirement{" "}
                          <span>{poolDetails?.token_requirement}</span>
                        </li>
                        <li>
                          Daily Rewards{" "}
                          <span>
                            {_getDailyRewards(
                              tokenQuantity,
                              poolDetails?.period_days
                            )}
                          </span>
                        </li>
                        <li>
                          Total Duration{" "}
                          {!poolDetails ? null : (
                            <span>{poolDetails?.period_days} Days</span>
                          )}
                        </li>
                        <li>
                          Pool Started on{" "}
                          {!poolDetails ? null : (
                            <span>{poolDetails?.started_at}</span>
                          )}
                        </li>
                      </ul>
                    </div>
                  </SimpleProjectItem>
                </div>

                <div className="col-md-6">
                  <SimpleProjectItem title={`Your Burns`} titleSteps>
                    <p>
                      You can view the amount of burns a wallet has on{" "}
                      <a href="https://wax.bloks.io/account/endlessnftft?loadContract=true&tab=Tables&account=endlessnftft&scope=endlessnftft&limit=100&table=assetburncd&action=giverewards">
                        Bloks.io
                      </a>
                      . This table will be displayed here shortly.
                    </p>
                  </SimpleProjectItem>
                </div>
              </div>

              <div className="ProjectDetails2_AllocationsTableSect">
                <SimpleSectionHeader
                  header={`Your Eligible Templates to Burn - Refresh the Page After Burn`}
                />
                <p>
                  **We're currently working on a redirect after burning and
                  claiming. Everything is functional just refresh. Daily rewards
                  are split between all users in the same percentage pool. For
                  example if you have 10 people in a 10% pool each individual
                  will earn 1% of the daily rewards.
                </p>

                <div className="ProjectDetails2_AllocationsTable">
                  <div className="ProjectDetails2_AllocationsTableHeadings">
                    <ProjectDetailsRow>
                      <ProjectDetailsColumn
                        columnNo={1}
                        value={`Template ID`}
                      />
                      <ProjectDetailsColumn columnNo={2} value={`Asset #`} />
                      <ProjectDetailsColumn
                        columnNo={3}
                        value={`*Daily Reward`}
                      />
                      <ProjectDetailsColumn columnNo={4} value={`Weight %`} />
                      <ProjectDetailsColumn columnNo={5} value={`Claimed`} />
                    </ProjectDetailsRow>
                  </div>

                  <div className="ProjectDetails2_AllocationsTableBody">
                    {templateAssetRows.length &&
                      templateAssetRows.map((ta, i) => {
                        const templateDetail = poolTemplateRows.find(
                          (pt) =>
                            pt.template_id === parseInt(ta.template.template_id)
                        );

                        const dailyRewards = _getDailyRewards(
                          tokenQuantity,
                          poolDetails?.period_days
                        );

                        const templateDailies = dailyRewards
                          ? dailyRewards * (templateDetail.percent / 100)
                          : 0;

                        return (
                          <ProjectDetailsRow key={i}>
                            <ProjectDetailsColumn
                              columnNo={1}
                              value={ta.template.template_id}
                            />
                            <ProjectDetailsColumn
                              columnNo={2}
                              value={`${ta.asset_id}`}
                            />
                            <ProjectDetailsColumn
                              columnNo={3}
                              className="pool-detail-page__text--blue"
                              value={`${templateDailies}`}
                            />
                            <ProjectDetailsColumn
                              columnNo={4}
                              value={`${templateDetail.percent}%`}
                            />

                            <ProjectDetailsColumn columnNo={5}>
                              <button
                                className="pool-detail-page__button--orange"
                                disabled={userBurning}
                                onClick={async () => {
                                  setUserBurning(true);
                                  const burnResponse =
                                    await _onHandleBurnClicked(
                                      activeUser,
                                      ta.asset_id,
                                      templateDetail.pool_id,
                                      activeUser.accountName
                                    );

                                  if (burnResponse.success) {
                                    setPopupOpen(true);
                                    setPopupTitle("Success");
                                    setPopupMessage(`Successfully Burned`);
                                  }

                                  if (!burnResponse.success) {
                                    setPopupOpen(true);
                                    setPopupTitle("Failed");
                                    setPopupMessage(
                                      burnResponse.responseData.message
                                    );
                                  }

                                  setUserBurning(false);
                                }}
                              >
                                {userBurning ? "BURNING..." : "BURN"}
                              </button>
                            </ProjectDetailsColumn>
                          </ProjectDetailsRow>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer className="mt-50" />
        </Fragment>
      ) : (
        <section className="loader_first">
          <div className="circular-spinner"></div>
        </section>
      )}
    </div>
  );
}

async function _onHandleBurnClicked(activeUser, assetId, poolId, accountName) {
  const atomicContractName = "atomicassets";
  const contractName = "endlessnftft";
  const burnTransactionData = [
    {
      contractAccount: atomicContractName,
      actionName: "transfer",
      data: {
        from: accountName,
        to: contractName,
        quantity: 1,
        asset_ids: [assetId],
        memo: `transfer of ${assetId} to ${contractName}`,
      },
    },
    {
      contractAccount: contractName,
      actionName: "askburn",
      data: {
        asset_id: assetId,
        pool_id: poolId,
        account: accountName,
      },
    },
  ];

  return await transactionSigner(activeUser, burnTransactionData);
}
