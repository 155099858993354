import React from "react";
import RoutePath from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./shared/css/main.css";
import "./shared/css/off-canvas.css";
import "./shared/css/sc-spacing.css";
import "./shared/css/responsive.css";
import "./shared/css/scmenu-main.css";
import "./shared/css/ico-moon-fonts.css";
import "animate.css";
import "./App.css";

function App({ ual }) {
	// console.log("💤💤💤", ual);
	return (
		<RoutePath
			activeUser={ual?.activeUser}
			showWaxModal={ual.showModal}
			onUserLogout={ual?.logout}
		/>
	);
}

export default App;
