import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Anchor } from "ual-anchor";
import { BrowserRouter } from "react-router-dom";
import { Wax } from "@eosdacio/ual-wax";
import { Wombat } from "ual-wombat";
import { UALProvider, withUAL } from "ual-reactjs-renderer";

const waxChain = {
	chainId: process.env.REACT_APP_WAX_CHAINID,
	rpcEndpoints: [
		{
			protocol: process.env.REACT_APP_WAX_PROTOCOL,
			host: process.env.REACT_APP_WAX_HOST,
			port: process.env.REACT_APP_WAX_PORT,
		},
	],
};

const AppWithUAL = withUAL(App);
const appName = process.env.REACT_APP_APP_NAME;
const anchor = new Anchor([waxChain], { appName: appName });
const waxcloud = new Wax([waxChain], { appName: appName });
const wombat = new Wombat([waxChain], { appName: appName });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<UALProvider
		chains={[waxChain]}
		authenticators={[wombat, waxcloud, anchor]}
		appName={appName}
	>
		<BrowserRouter>
			<AppWithUAL />
		</BrowserRouter>
	</UALProvider>
);

reportWebVitals();
