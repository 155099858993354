import * as waxjs from "@waxio/waxjs/dist";
import waxUrl from "../constants/waxUrl";

const { contract, waxBlocks, rpcEndpoint } = waxUrl;
const wax = new waxjs.WaxJS({ rpcEndpoint: waxUrl.rpcEndpoint });

export async function getPoolDetails(rows = 1000, table = "poolsd") {
  const url = `${waxBlocks}/account/${contract}?loadContract=true&tab=Tables&account=${contract}&scope=${contract}&limit=${rows}&table=${table}`;
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getPoolDetailRows(
  rows = 1000,
  table = "poolsd",
  showPayer = false
) {
  const url = `${rpcEndpoint}/v1/chain/get_table_rows`;
  const urlBody = {
    code: contract,
    scope: contract,
    json: true,
    key_type: "",
    limit: rows,
    table: table,
    show_payer: showPayer,
  };

  const options = {
    method: "POST",
    body: JSON.stringify(urlBody),
    mode: "cors",
    credentials: "omit",
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}

export async function getPoolTemplates(
  poolNumber = 0,
  table = "pooltemplsd",
  rows = 1000,
  showPayer = false
) {
  const jsonResponse = await wax.rpc.get_table_rows({
    limit: rows,
    code: contract,
    scope: contract,
    table: table,
    json: true,
    upper_bound: poolNumber,
    lower_bound: poolNumber,
    index_position: 2,
    key_type: "i64",
    show_payer: showPayer,
  });

  if (jsonResponse.rows.length > 0) {
    const json = jsonResponse.rows;
    return { success: true, responseData: json };
  } else {
    const json = await jsonResponse.json();
    return { success: false, responseData: json };
  }
}
