import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Button, ButtonLink } from "../Buttons";
import tokens from "../../constants/tokens";

import GnomesIcon from "../../shared/images/project/cs.png";
import GnokenIcon from "../../shared/images/project/gnoken.png";

import LimboProjectIcon from "../../shared/images/project/limbo_logo.jpg";
import LimboIcon from "../../shared/images/project/limbo.png";

import MofiProjectIcon from "../../shared/images/project/mofight.png";
import MofiIcon from "../../shared/images/project/motoken.png";

import HumonProjectIcon from "../../shared/images/project/humon.png";
import BluIcon from "../../shared/images/project/bludac.png";

import LbProjectIcon from "../../shared/images/project/lblogo.png";
import LbIcon from "../../shared/images/project/lanbx.png";

import CryptoPupsProjectIcon from "../../shared/images/project/pups.png";
import KasuIcon from "../../shared/images/project/pupt.PNG";

import CWLProjectIcon from "../../shared/images/project/cwl.png";
import CWLIcon from "../../shared/images/project/cwl.png";

import DegenProjectIcon from "../../shared/images/project/degen-logo.png";
import DegenIcon from "../../shared/images/project/degen-coin.png";

export function GameBoxContainer({
  className = "",
  bordered = true,
  bannerButton = false,
  bannerBottom = false,
  progressBar = false,
  currency = "",
  timerDuration = 0,
  name = "",
  description = "",
  details = "",
  allocationDetails = "",
  progressDetails = "",
  bannerDetails = "",
  bannerLabel = "",
  percentage = 0,
  buttonDisabled = false,
  buttonText = "",
  onButtonClicked,
}) {
  const borderHoverClass = classNames({
    "active-shape": bordered,
  });

  const _getProjectImage = (projectToken) => {
    const {gnoken, limbo, mofi, blu, lanbx, cwl, kasu, booty} = tokens;

    switch (projectToken) {
      case gnoken:
        return GnomesIcon;
      case limbo:
        return LimboProjectIcon;
			case mofi:
        return MofiProjectIcon;
			case blu:
        return HumonProjectIcon;
			case lanbx:
        return LbProjectIcon;
		    case cwl:
        return CWLProjectIcon;
			case kasu:
        return CryptoPupsProjectIcon;
			case booty:
        return DegenProjectIcon;
      default:
        return GnomesIcon;
    }
  };

  const _getLogo = (projectToken) => {
    const {gnoken, limbo, mofi, blu, lanbx, cwl, kasu, booty} = tokens;

    switch (projectToken) {
      case gnoken:
        return GnokenIcon;
      case limbo:
        return LimboIcon;
			case mofi:
        return MofiIcon;
		case blu:
        return BluIcon;
			case lanbx:
        return LbIcon;
		case cwl:
			return CWLIcon;
			case kasu:
        return KasuIcon;
			case booty:
        return DegenIcon;
      default:
        return GnokenIcon;
    }
  };

  return (
    <div
      className={`game-price-item hover-shape-inner ${className} ${borderHoverClass}`}
    >
      <div className="game-price-inner">
        <div className="total-price">
          <div className="price-inner d-flex mb-45 md-mb-20">
            <div className="image-icon">
              <img src={_getProjectImage(currency)} alt="gnomeIcon" />
            </div>
            <div className="price-details">
              <h3 className="mb-15">{name}</h3>
              <div className="dsc">{description}</div>
            </div>
          </div>
          {!details ? null : <div className="all-raise mb-10">{details}</div>}
        </div>
        <div className="allocation-max text-center">
          <img src={_getLogo(currency)} width="100" alt="gnokenIcon" />
          {!allocationDetails ? null : (
            <div className="allocation">{allocationDetails}</div>
          )}
        </div>
        <div className="targeted-raise">
          <div className="all-raise mb-10">REWARDS END IN</div>
          <div className="timer timer_1">
            <ul>
              <li className="days"></li>
              <li className="hours"></li>
              <li className="minutes"></li>
              <li className="seconds"></li>
            </ul>
          </div>
          <div className="targeted-raise text-end">{progressDetails}</div>
        </div>
      </div>

      {!progressBar ? null : (
        <div className="progress-inner">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped"
              style={{
                width: `${percentage}%`,
                minWidth: "0%",
                maxWidth: "100%",
              }}
            ></div>
          </div>
        </div>
      )}

      {!bannerBottom ? null : (
        <div className="banner-bottom-content mt-40">
          <div className="btn-area">
            {!bannerButton ? null : (
              <Button
                IsButtonDisabled={buttonDisabled}
                hoverEffect="readon"
                className="white-shape-small"
                buttonText={buttonText}
                onButtonClicked={onButtonClicked}
              />
            )}
          </div>

          <div className="text-content">
            <div className="TotalRisedProgressHeadings">
              <h3 style={{ color: "#43f4c7 " }}>
                {bannerLabel}
                <span style={{ color: "#43f4c7 " }}>{bannerDetails}</span>
              </h3>
            </div>
          </div>
        </div>
      )}

      <span className="border-shadow shadow-1"></span>
      <span className="border-shadow shadow-2"></span>
      <span className="border-shadow shadow-3"></span>
      <span className="border-shadow shadow-4"></span>
      <span className="hover-shape-bg hover_shape1"></span>
      <span className="hover-shape-bg hover_shape2"></span>
      <span className="hover-shape-bg hover_shape3"></span>
    </div>
  );
}

export function ProjectBoxContainer({
  bannerImage,
  name = "",
  description = "",
  details = "",
  progressDetails = "",
  progressGoal = "",
  requirements = "",
  dailies = "",
  burns = "",
  percentage = 0,
  buttonLink = "/",
  buttonText = "",
}) {
  return (
    <div className="Project_clasic_2_Container">
      <div className="row">
        <div className="col-md-6">
          <div className="Project_clasic_2_ContainerLeft">
            <div className="Project_clasic_2_ContainerLeftContent">
              <div className="Project_clasic_2_ContainerLeftImg">
                <img src={bannerImage} alt="img" className="img-fluid" />
              </div>
              <div className="ProjectClasic2_Timer">
                <div className="price-counter">
                  <div className="timer timer_1">
                    <ul>
                      <li className="days">
                        03<span>D</span>
                      </li>
                      <li className="hours">
                        01<span>H</span>
                      </li>
                      <li className="minutes">
                        13<span>M</span>
                      </li>
                      <li className="seconds">
                        56<span>S</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="Project_clasic_2_LeftContentImg">
                <img src={GnomesIcon} width="100" alt="" />
              </div>
            </div>
          </div>
          <div className="Project_clasic_2_ContainerRight"></div>
        </div>
        <div className="col-md-6">
          <div className="Project_clasic_2Container_RightSect">
            <div className="Project_clasic_2Container_RightHeadings">
              <h2>
                <Link to={"/pool-details"}>{name}</Link>
              </h2>
              <p>{description}</p>
              <span>
                <img
                  src={GnokenIcon}
                  width="50"
                  alt="chain"
                  className="img-fluid"
                />
              </span>
            </div>
            <div className="TotalRisedProgressSect">
              <div className="TotalRisedProgressHeadings">
                <h3>
                  Total Reward: <span>{progressGoal}</span>
                </h3>
                <p style={{ color: "#1fd9a4" }}>
                  <span className="counter">{percentage}</span>% Completed
                </p>
              </div>
              <div className="Access_Allocation_ParticipantsSect">
                <ul>
                  <li>
                    <span>Wallet Holding Requirement</span>
                    <p>{requirements}</p>
                  </li>
                  <li>
                    <span>Daily Rewards</span>
                    <p>{dailies}</p>
                  </li>
                  <li>
                    <span>Total Burns</span>
                    <p>{burns}</p>
                  </li>
                </ul>
              </div>
              <hr />

              <ButtonLink
                className="banner-btn"
                link={buttonLink}
                buttonText={buttonText}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SimpleBoxContainer({
  className = "",
  bordered = true,
  children,
}) {
  const borderHoverClass = classNames({
    "active-shape": bordered,
  });

  return (
    <div className="gamfi-footer-section">
      <div
        className={`footer-cta-area text-center hover-shape-inner ${className} ${borderHoverClass}`}
      >
        {children}
        <span className="border-shadow shadow-1"></span>
        <span className="border-shadow shadow-2"></span>
        <span className="border-shadow shadow-3"></span>
        <span className="border-shadow shadow-4"></span>
        <span className="hover-shape-bg hover_shape1"></span>
        <span className="hover-shape-bg hover_shape2"></span>
        <span className="hover-shape-bg hover_shape3"></span>
      </div>
    </div>
  );
}
