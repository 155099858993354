import * as waxjs from "@waxio/waxjs/dist";
import waxUrl from "../constants/waxUrl";

const { contract } = waxUrl;
const wax = new waxjs.WaxJS({ rpcEndpoint: waxUrl.rpcEndpoint });

export async function getRewardsBalances(
  table = "rewardsbalcd",
  rows = 1000,
  showPayer = false
) {
  const jsonResponse = await wax.rpc.get_table_rows({
    limit: rows,
    code: contract,
    scope: contract,
    table: table,
    json: true,
    show_payer: showPayer,
  });

  if (jsonResponse?.rows.length > 0) {
    const json = jsonResponse.rows;
    return { success: true, responseData: json };
  } else {
    return { success: false };
  }
}
