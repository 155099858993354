import React from "react";

import StepsImage from "../../shared/images/icons/steps.png";

export function SectionHeader({ header, subHeader }) {
	return (
		<div className="sec-heading">
			<div className="sub-inner">
				<span className="sub-title">{subHeader}</span>
				<img className="heading-left-image" src={StepsImage} alt="stepsImage" />
			</div>
			<h2 className="title">{header}</h2>
		</div>
	);
}

export function SimpleSectionHeader({ header }) {
	return (
		<h2>
			{header}{" "}
			<span>
				<img className="heading-left-image" src={StepsImage} alt="stepsImage" />
			</span>{" "}
		</h2>
	);
}
