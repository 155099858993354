import { Routes, Route } from "react-router-dom";
import RoutePaths from "./routhPaths";

import LoginPage from "../pages/LoginPage";
import ProjectPage from "../pages/ProjectPage";
import PoolDetailPage from "../pages/PoolDetailPage";

export default function RoutePath({ activeUser, showWaxModal, onUserLogout }) {
	const { home, project, poolDetails } = RoutePaths;

	return (
		<Routes>
			<Route
				exact
				path={home}
				element={
					<LoginPage
						activeUser={activeUser}
						showWaxModal={showWaxModal}
						onUserLogout={onUserLogout}
					/>
				}
			/>
			<Route
				exact
				path={project}
				element={
					<ProjectPage
						activeUser={activeUser}
						showWaxModal={showWaxModal}
						onUserLogout={onUserLogout}
					/>
				}
			/>
			<Route
				exact
				path={`${poolDetails}/:id`}
				element={
					<PoolDetailPage
						activeUser={activeUser}
						showWaxModal={showWaxModal}
						onUserLogout={onUserLogout}
					/>
				}
			/>
		</Routes>
	);
}
