import waxUrl from "../constants/waxUrl";

const { baseAtomicAssets } = waxUrl;

export async function getAccountAssetsByTemplate(
  templateId = "",
  accountName = ""
) {
  const url = `${baseAtomicAssets}/atomicassets/v1/assets?template_id=${templateId}&owner=${accountName}`;
  const options = {
    method: "GET",
  };

  const response = await fetch(url, options);
  if (response.status === 200) {
    const json = await response.json();
    return { success: true, responseData: json };
  } else {
    const json = await response.json();
    return { success: false, responseData: json };
  }
}
