import React from "react";
import { Link } from "react-router-dom";
import RoutePaths from "../../routes/routhPaths";

import StepsImage from "../../shared/images/icons/steps.png";
import StepsImage2 from "../../shared/images/icons/steps2.png";
import Logo from "../../shared/images/logo.png";

export default function Footer({ className = "" }) {
	const { home } = RoutePaths;
	return (
		<div className={`gamfi-footer-section ${className}`}>
			<div className="footer-area">
				<div className="container">
					<div className="sec-heading text-center">
						<div className="sub-inner mb-52 mb-mb-30">
							<img
								className="heading-right-image"
								src={StepsImage2}
								alt="stepsImage2"
							/>
							<span className="sub-title white-color">Find us on Social</span>
							<img
								className="heading-left-image"
								src={StepsImage}
								alt="stepsImage"
							/>
						</div>
					</div>
					<div className="footer-listing text-center mb-100 md-mb-70 xs-mb-50">
						<ul className="footer-icon-list">
							<li>
								<Link to={home}>
									<i className="icon-twitter"></i>
								</Link>
							</li>
							<li>
								<Link to={home}>
									<i className="icon-telegram"></i>
								</Link>
							</li>
							<li>
								<Link to={home}>
									<i className="icon-discord"></i>
								</Link>
							</li>
						</ul>
					</div>
					<div className="footer-logo text-center mb-45">
						<img src={Logo} alt="Footer-logo" />
					</div>
					<div className="footer-mainmenu text-center mb-20">
						<ul>
							<li>
								<Link to={home}>NFT Projects</Link>
							</li>
							<li>
								<Link to={home}>Tiers</Link>
							</li>
							<li>
								<Link to={home}>Apply</Link>
							</li>
							<li>
								<Link to={home}>Guide</Link>
							</li>
						</ul>
					</div>
					<div className="copyright-area text-center mb-0">
						<div className="dsc mb-37 md-mb-25">
							Copyright © 2022. All Rights Reserved by PWNED Studio, LLC.
						</div>
					</div>
					<div className="scrollup text-center">
						<a href="#gamfi-header">
							<i className="icon-arrow_up"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
