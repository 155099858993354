import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ButtonLink } from "../../components/Buttons";
import { SectionHeader } from "../../components/Sections";
import { ProjectItem } from "../../components/ProjectItem";
import { TierTable } from "../../components/TierTable";
import {
	KeyPointItem,
	LivePoolItem,
	ParticipationItem,
} from "../../components/ItemSets";
import {
	GameBoxContainer,
	SimpleBoxContainer,
} from "../../components/Container";

import NFTBurnLogo from "../../shared/images/homeV3/nft-burn.png";
import UFOLogo from "../../shared/images/homeV3/uFO.png";

import GnomesIcon from "../../shared/images/project/gnomes.png";

import DollarIcon from "../../shared/images/icons/dollar-dollar-color.svg";
import RocketIcon from "../../shared/images/icons/rocket-dynamic-color.svg";

import ParticipationImage1 from "../../shared/images/icons/participate-image.png";
import ParticipationImage2 from "../../shared/images/icons/participate-image2.png";
import ParticipationImage3 from "../../shared/images/icons/participate-image3.png";

import TierImage1 from "../../shared/images/icons/Tier1.png";
import TierImage2 from "../../shared/images/icons/Tier2.png";
import TierImage3 from "../../shared/images/icons/Tier3.png";
import TierImage4 from "../../shared/images/icons/Tier4.png";

import KeyIcon1 from "../../shared/images/icons/Key_icon1.png";
import KeyIcon2 from "../../shared/images/icons/Key_icon2.png";
import KeyIcon3 from "../../shared/images/icons/Key_icon3.png";
import KeyIcon4 from "../../shared/images/icons/Key_icon4.png";

export default function LoginPage({ activeUser, showWaxModal, onUserLogout }) {
	const [loader, setLoader] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoader(false);
		}, 600);
	}, []);

	return (
		<div className="login-page">
			{!loader ? null : (
				<section className="loader_first">
					<div className="circular-spinner"></div>
				</section>
			)}

			<Header
				headerStyle="transparent"
				activeUser={activeUser}
				showWaxModal={showWaxModal}
				onUserLogout={onUserLogout}
			/>

			<div id="sc-banner" className="sc_banner_V3 banner-bg position-relative">
				<div className="container">
					<div className="banner-content V3_BanerContent">
						<div className="row">
							<div className="col-md-6">
								<div className="sc_banner_V3_left">
									<h2
										className="wow fadeInUp"
										style={{
											visibility: "visible",
											animationDuration: "0.6s",
											animationDelay: "0.4s",
											animationName: "fadeInUp",
										}}
									>
										REWARD COLLECTORS
										<br /> BURN-TO{" "}
										<span>
											<img src={DollarIcon} alt="" className="img-fluid" />
										</span>{" "}
										EARN LAUNCHPAD{" "}
										<span>
											<img src={RocketIcon} alt="" className="img-fluid" />
										</span>
									</h2>
									<p
										className="wow fadeInUp"
										style={{
											visibility: "visible",
											animationDuration: "0.7s",
											animationDelay: "0.5s",
											animationName: "fadeInUp",
										}}
									>
										Getting started is simple. Projects set a few template IDs
										that they want collectors to burn. Each template has a
										percentage of distributions with a time limit. Collector
										earns daily from the token reward pool.
									</p>
									<div
										className="gamfi_V2_hero_Btns wow fadeInUp"
										style={{
											visibility: "visible",
											animationDuration: "0.8s",
											animationDelay: "0.6s",
											animationName: "fadeInUp",
										}}
									>
										<ButtonLink
											externalLink
											hoverEffect="hover"
											className="readon white-btn VIEW_Projects_Btn px-5"
											buttonText="Whitelisted Projects"
											link="/project"
										/>

										<ButtonLink
											externalLink
											className="readon Apply_Project_Btn"
											buttonText="Apply Now"
											link="https://docs.google.com/forms/d/e/1FAIpQLSdQQiWUiaGz_tbPf5toJcaLHUWD4YbXxlUYaQKj3tWWK7pcow/viewform"
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="sc_banner_V3_right">
									<div className="Animetion_Avater">
										<img src={NFTBurnLogo} alt="img" className="img-fluid" />
									</div>
									<ul>
										<li>
											<h3>NFTs Burned</h3>
											<h4 className="counter-number">
												<span className="counter">0</span>
											</h4>
										</li>
										<li>
											<h3>PROJECTS LISTED</h3>
											<h4 className="counter-number">
												<span className="counter">0</span>
											</h4>
										</li>
										<li className="m-0">
											<h3>REWARDS</h3>
											<h4 className="counter-number">
												<span className="counter">0</span>
											</h4>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					className="Ufo_Img wow slideInLeft"
					data-wow-delay="300ms"
					data-wow-duration="2500ms"
					// style="visibility: visible; animation-duration: 2500ms; animation-delay: 300ms; animation-name: slideInLeft;"
				>
					<img src={UFOLogo} alt="ufo" className="img-fluid" />
				</div>
			</div>

			<div className="gamfi-about-secion pb-80 md-pb-45">
				<div className="container">
					<div
						className="banner-slider-inner wow fadeInUp"
						style={{
							visibility: "visible",
							animationDuration: "0.7s",
							animationDelay: "0.2s",
							animationName: "fadeInUp",
						}}
					>
						<OwlCarousel className="sc-carousel owl-carousel" loop items={1}>
							<GameBoxContainer
								progressBar
								percentage={75}
								name="EXAMPLE: Gnome Series"
								description="3,000,000 $GNOKEN"
								details="1,500,000 Distributed"
								progressDetails="TOTAL BURNED: 15 NFTs"
							/>
						</OwlCarousel>
					</div>
					<div
						className="pt-130 md-pt-80 heading-area align-items-center d-flex justify-content-between wow fadeInUp mb-30"
						data-wow-delay="0.2s"
						data-wow-duration="0.4s"
					>
						<SectionHeader
							header="Launchpad for NFT Creators & Collectors"
							subHeader="3 easy ways to use NlessFT"
						/>
					</div>
					<div className="participate-area row">
						<div className="col-lg-4">
							<ParticipationItem
								className="wow fadeInUp"
								imgSrc={ParticipationImage1}
								name="Create a Pool"
								description="Create a pool of which templates you'd want collectors to
								burn. Add the reward to be distributed with a time frame.
								Let NlessFT do everything else!"
							/>
						</div>
						<div className="col-lg-4">
							<ParticipationItem
								className="wow fadeInUp mr-30"
								imgSrc={ParticipationImage2}
								name="Burn to Earn"
								description="As a collector you transfer the NFT on our website. We burn
								the NFT and you start earning the reward pool you entered.
								It is that simple."
							/>
						</div>
						<div className="col-lg-4">
							<ParticipationItem
								className="wow fadeInUp mr-20"
								imgSrc={ParticipationImage3}
								name="Claim Rewards Daily"
								description="You can claim your rewards daily. Each pool may have a
								minimum requirement that you must hold of X token to claim
								daily. NlessFT has a 10% fee when claiming."
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="gamfi-project-section main-project-area">
				<div className="container">
					<div className="sec-inner align-items-center d-flex justify-content-between">
						<SectionHeader
							header="Get Started"
							subHeader="Examples Burn to Earn"
						/>

						<LivePoolItem poolText={`LIVE Pools`} />
					</div>

					<div className="row align-items-center">
						<div className="col-lg-4 col-md-6">
							<ProjectItem
								title={`Gnome Series`}
								description={`3,000,000 $GNOKEN`}
								daysLeft={8}
								requirement={`50,000 $GNOKEN`}
								daily={`100,000 $GNOKEN`}
								duration={`30 Days`}
							/>
						</div>
						<div className="col-lg-4 col-md-6">
							<ProjectItem
								title={`Gnome Series`}
								description={`3,000,000 $GNOKEN`}
								daysLeft={8}
								requirement={`50,000 $GNOKEN`}
								daily={`100,000 $GNOKEN`}
								duration={`30 Days`}
							/>
						</div>
						<div className="col-lg-4 col-md-6">
							<ProjectItem
								title={`Gnome Series`}
								description={`3,000,000 $GNOKEN`}
								daysLeft={8}
								requirement={`50,000 $GNOKEN`}
								daily={`100,000 $GNOKEN`}
								duration={`30 Days`}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="gamfi_TierSect">
				<div className="container">
					<div
						className="sec-inner align-items-center d-flex justify-content-between wow fadeInUp mb-50"
						data-wow-delay="0.2s"
						data-wow-duration="0.4s"
					>
						<SectionHeader header="Coming Soon" subHeader="Member Tiers" />
					</div>
					<div className="row">
						<div className="col-md-3">
							<TierTable
								tierColumn={1}
								imageSrc={TierImage1}
								name="TIER 1"
								description="Regular (FREE)"
								requirements="Assigned by Reward Pool"
								reward="Included"
								promotion="Not Included"
								poolWeight={0}
							/>
						</div>

						<div className="col-md-3">
							<TierTable
								tierColumn={2}
								tierBlocks={1}
								imageSrc={TierImage2}
								name="TIER 2"
								description="Gold"
								requirements="25% Less Than Requirement"
								reward="Included + Options"
								promotion="Not Included"
								poolWeight={2}
							/>
						</div>

						<div className="col-md-3">
							<TierTable
								tierColumn={3}
								tierBlocks={2}
								imageSrc={TierImage3}
								name="TIER 3"
								description="Platinum"
								requirements="50% Less Than Requirement"
								reward="Included + Options"
								promotion="Included"
								poolWeight={5}
							/>
						</div>

						<div className="col-md-3">
							<TierTable
								tierColumn={4}
								tierBlocks={3}
								imageSrc={TierImage4}
								name="TIER 4"
								description="Diamond"
								requirements="No Requirement"
								reward="Included + Options"
								promotion="Included"
								poolWeight={10}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="Key_Points_section Key_PointsV2_section main-project-area">
				<div className="container">
					<div className="sec-inner align-items-center mb-30">
						<SectionHeader
							header="Helping NFT Projects Grow"
							subHeader=">Launchpad with a Purpose"
						/>

						<div className="Key_Points_Conttent">
							<div className="row">
								<div
									className="col-md-3 wow fadeInUp"
									data-wow-delay="0.2s"
									data-wow-duration="0.4s"
									// style="visibility: visible; animation-duration: 0.4s; animation-delay: 0.2s; animation-name: fadeInUp;"
								>
									<KeyPointItem
										imgSrc={KeyIcon1}
										name="Decentralized"
										description="No gatekeeping. No voting projects in. Anyone can create
												a reward pool."
									/>
								</div>
								<div
									className="col-md-3 wow fadeInUp"
									data-wow-delay="0.3s"
									data-wow-duration="0.5s"
									// style="visibility: visible; animation-duration: 0.5s; animation-delay: 0.3s; animation-name: fadeInUp;"
								>
									<KeyPointItem
										imgSrc={KeyIcon2}
										name="Security"
										description="A launchpad that connects the community with a smart
										contract."
									/>
								</div>
								<div
									className="col-md-3 wow fadeInUp"
									data-wow-delay="0.4s"
									data-wow-duration="0.6s"
									// style="visibility: visible; animation-duration: 0.6s; animation-delay: 0.4s; animation-name: fadeInUp;"
								>
									<KeyPointItem
										imgSrc={KeyIcon3}
										name="Fair Allocations"
										description="Transparency of reward pools and our fees to keep the
										platform running."
									/>
								</div>
								<div
									className="col-md-3 wow fadeInUp"
									data-wow-delay="0.5s"
									data-wow-duration="0.7s"
									// style="visibility: visible; animation-duration: 0.7s; animation-delay: 0.5s; animation-name: fadeInUp;"
								>
									<KeyPointItem
										imgSrc={KeyIcon4}
										name="NFT Deflation"
										description="Less NFTs in supply will create a demand. Get rewarded
										for burning."
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="gamfi-partner-section pb-110 md-pb-80">
				<div className="container">
					<div
						className="sec-heading text-center wow fadeInUp"
						data-wow-delay="0.2s"
						data-wow-duration="0.4s"
					>
						<SectionHeader
							header="Collections That Helped Us Launch"
							subHeader="Our Launch Partners"
						/>
					</div>
					<div
						className="gamfi-partner-inner wow fadeInUp"
						data-wow-delay="0.2s"
						data-wow-duration="0.4s"
					>
						<div className="partner-image">
							{Array(7)
								.fill(1)
								.map((p) => (
									<Link to={"/"}>
										<img src={GnomesIcon} width="100" alt="partnerImage1" />
									</Link>
								))}
						</div>
						<div className="partner-image">
							{Array(7)
								.fill(1)
								.map((p) => (
									<Link to={"/"}>
										<img src={GnomesIcon} width="100" alt="partnerImage2" />
									</Link>
								))}
						</div>
					</div>
				</div>
			</div>
			<div className="gamfi-application-section">
				<div className="container">
					<SimpleBoxContainer>
						<h2 className="title mb-15">Create a Reward Pool</h2>
						<div className="dsc mb-40 md-mb-30">
							Launching with us is easy. No gatekeeping. The way it should be.
						</div>

						<ButtonLink
							externalLink
							className="banner-btn"
							buttonText="Apply Now!"
							link="https://docs.google.com/forms/d/e/1FAIpQLSdQQiWUiaGz_tbPf5toJcaLHUWD4YbXxlUYaQKj3tWWK7pcow/viewform"
						/>
					</SimpleBoxContainer>
				</div>
			</div>

			<Footer />
		</div>
	);
}
