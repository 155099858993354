import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import GnomesIcon from "../../shared/images/project/gnomes.png";
import GnokenIcon from "../../shared/images/project/gnoken.png";
import StepsImage from "../../shared/images/icons/steps.png";

export function ProjectItem({
	bordered = true,
	className = "",
	link = "/",
	titleLink = "/",
	title,
	description,
	requirement = 0,
	daysLeft = 0,
	daily = 0,
	duration = 0,
	burns = 0,
	telegram = "",
	twitter = "",
	discord = "",
}) {
	const hoverClass = classNames({ "hover-shape-border": bordered });

	return (
		<div className={`project-item ${className} ${hoverClass}`}>
			<div className="project-info d-flex">
				<Link to={link}>
					<img src={GnomesIcon} width="75" alt="gnomeImage" />
				</Link>
				<div className="project-auother">
					<h4 className="mb-10">
						<Link to={titleLink}>{title}</Link>
					</h4>
					<div className="dsc">{description}</div>
				</div>
			</div>
			<div className="project-content">
				<div className="project-header d-flex justify-content-between">
					<div className="heading-title">
						<h4>{daysLeft} Days Left</h4>
					</div>
					<div className="project-icon">
						<img src={GnokenIcon} width="35" alt="gnokenImage" />
					</div>
				</div>
				<div className="project-media">
					<ul className="project-listing">
						<li>
							Wallet Requirement <span>{requirement}</span>
						</li>
						<li>
							Daily Rewards <span>{daily}</span>
						</li>
						<li>
							Total Duration <span>{duration}</span>
						</li>
						<li>
							Total Burns <span>{burns}</span>
						</li>
						<li className="social-share">
							Connect
							<ul className="social-icon-list">
								<li>
									<Link to={telegram}>
										<i className="icon-telegram"></i>
									</Link>
								</li>
								<li>
									<Link to={twitter}>
										<i className="icon-twitter"></i>
									</Link>
								</li>
								<li>
									<Link to={discord}>
										<i className="icon-discord"></i>
									</Link>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<span className="border-shadow shadow-1"></span>
			<span className="border-shadow shadow-2"></span>
			<span className="border-shadow shadow-3"></span>
			<span className="border-shadow shadow-4"></span>
		</div>
	);
}

export function SimpleProjectItem({
	bordered = true,
	titleSteps = false,
	borderShadow = false,
	className = "",
	title,
	description,
	children,
}) {
	const hoverClass = classNames({ "hover-shape-border": bordered });

	return (
		<div className={`project-item ${className} ${hoverClass}`}>
			<div className="project-info d-flex">
				{!title ? null : (
					<h4 className="mb-10">
						{title}{" "}
						{!titleSteps ? null : <img src={StepsImage} alt="stepsImage" />}
					</h4>
				)}
				{!description ? null : <div className="dsc">{description}</div>}
			</div>
			<div className="project-content">{children}</div>

			{!borderShadow ? null : (
				<Fragment>
					<span className="border-shadow shadow-1"></span>
					<span className="border-shadow shadow-2"></span>
					<span className="border-shadow shadow-3"></span>
					<span className="border-shadow shadow-4"></span>
				</Fragment>
			)}
		</div>
	);
}
