import React from "react";

export function LivePoolItem({ poolText }) {
	return (
		<div className="liveProoject_Headings">
			<h2 className="mb-50">{poolText}</h2>
			<div className="video__icon">
				<div className="circle--outer"></div>
				<div className="circle--inner"></div>
			</div>
		</div>
	);
}

export function KeyPointItem({ imgSrc, name, description }) {
	return (
		<div className="Key_Points_Items">
			<div className="Key_Points_Item_img">
				<img src={imgSrc} alt="icon" className="img-fluid" />
			</div>
			<div className="Key_Points_Item_Text">
				<h3>{name}</h3>
				<p>{description}</p>
			</div>
		</div>
	);
}

export function ParticipationItem({
	className = "",
	imgSrc,
	name = "",
	description = "",
}) {
	return (
		<div className={`participate-item d-flex ${className}`}>
			<div className="number-image">
				<img src={imgSrc} alt="participationImage" />
			</div>
			<div className="participate-info">
				<h4 className="mb-10">{name}</h4>
				<p className="description">{description}</p>
			</div>
		</div>
	);
}
