import React, { useState, useEffect, Fragment } from "react";
import { DateTime } from "luxon";
import RoutePath from "../../routes/routhPaths";

import { getPoolDetailRows } from "../../services/PoolService";

import Header from "../../components/Header/";
import Footer from "../../components/Footer";
import { LivePoolItem } from "../../components/ItemSets";
import { ProjectBoxContainer } from "../../components/Container";
import { SectionHeader } from "../../components/Sections";
import { PreviousItemRow } from "../../components/PreviousItem";

import { removeGnokenSuffix } from "../../utils/helpers";

import FeaturedImage from "../../shared/images/project/featured.png";

export default function ProjectPage({
  activeUser,
  showWaxModal,
  onUserLogout,
}) {
  const [loader, setLoader] = useState(true);
  const [poolDetailRows, setPoolDetailRows] = useState([]);
  const [featuredPools, setFeaturedPools] = useState([]);

  const _serviceInit = async () => {
    try {
      const detailRows = await getPoolDetailRows();

      !detailRows.success
        ? setPoolDetailRows([])
        : setPoolDetailRows(detailRows.responseData.rows);
    } catch (e) {
      console.log("🎶🎶🎶", e);
    }
  };

  useEffect(() => {
    _serviceInit();
    setTimeout(() => {
      setLoader(false);
    }, 600);
  }, [activeUser]);

  return (
    <div className="project-page">
      {!loader ? (
        <Fragment>
          <Header
            activeUser={activeUser}
            showWaxModal={showWaxModal}
            onUserLogout={onUserLogout}
          />

          <div className="gamfi-breadcrumbs-section ProjectClasicBreadCumSect">
            <div className="container">
              <div className="Project_clasic_2_Sect">
                {!featuredPools.length ? null : (
                  <Fragment>
                    <LivePoolItem poolText={`FEATURED REWARD POOLS`} />

                    <ProjectBoxContainer
                      bannerImage={FeaturedImage}
                      name={`Gnome Series`}
                      percentage={50}
                      description={`Eligible Templates: 10`}
                      progressGoal={`3,000,000 $GNOKEN`}
                      requirements={`50,000 $GNOKEN`}
                      dailies={`100,000 $GNOKEN`}
                      burns={`25 NFTs`}
                      buttonLink={RoutePath.poolDetails}
                      buttonText="View Reward Pool"
                    />
                  </Fragment>
                )}

                <div className="gamfi-previous-section ProjectClasic_PreviousSection pb-140 md-pb-50">
                  <div className="container">
                    <SectionHeader
                      header="ACTIVE POOLS"
                      subHeader="REWARD POOLS"
                    />

                    <div className="row align-items-center">
                      <div
                        className="col-md-12 wow fadeInUp"
                        data-wow-delay="0.3s"
                        data-wow-duration="0.5s"
                      >
                        <div className="previous-mainmenu mb-15">
                          <ul className="menu-list">
                            <li className="list1 list1Custom">Project name</li>
                            <li className="list2 list2Custom">Token Reward</li>
                            <li className="list3 list3Custom">ENDS IN</li>
                            <li className="list4 list4Custom">Daily Rewards</li>
 							<li className="list5 list5Custom">Type</li>
                          </ul>
                        </div>

                        {poolDetailRows.length &&
                          poolDetailRows
                            .filter((pr) => {
                              const periodDays = parseInt(pr.period_days);
                              const startDate = DateTime.fromISO(pr.started_at);
                              const dateToday = DateTime.now().toUTC();

                              return periodDays
                                ? dateToday.diff(startDate, "days").days <=
                                    periodDays
                                : true;
                            })
                            .map((pr, i) => {
                              const tokenQuantity = parseInt(
                                removeGnokenSuffix(pr.token_quantity)
                              );

                              const rewards = tokenQuantity / pr.period_days;

                              return (
                                <PreviousItemRow
                                  key={i}
                                  link={`${RoutePath.poolDetails}/${pr.id}`}
                                  name={pr.token_contract}
                                  currency={pr.token_contract}
                                  description={`${tokenQuantity}`}
                                  duration={`${pr.period_days} Days`}
                                  rewards={rewards.toFixed(2)}
                                />
                              );
                            })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      ) : (
        <section className="loader_first">
          <div className="circular-spinner"></div>
        </section>
      )}
    </div>
  );
}
