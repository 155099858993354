import React from "react";
import { Link } from "react-router-dom";

import GnomesIcon from "../../shared/images/project/cs.png";
import GnokenIcon from "../../shared/images/project/gnoken.png";

import LimboProjectIcon from "../../shared/images/project/limbo_logo.jpg";
import LimboIcon from "../../shared/images/project/limbo.png";

import MofiProjectIcon from "../../shared/images/project/mofight.png";
import MofiIcon from "../../shared/images/project/motoken.png";

import HumonProjectIcon from "../../shared/images/project/humon.png";
import BluIcon from "../../shared/images/project/bludac.png";

import LbProjectIcon from "../../shared/images/project/lblogo.png";
import LbIcon from "../../shared/images/project/lanbx.png";

import CryptoPupsProjectIcon from "../../shared/images/project/pups.png";
import KasuIcon from "../../shared/images/project/pupt.PNG";

import CWLProjectIcon from "../../shared/images/project/cwl.png";
import CWLIcon from "../../shared/images/project/cwl.png";

import DegenProjectIcon from "../../shared/images/project/degen-logo.png";
import DegenIcon from "../../shared/images/project/degen-coin.png";

import tokens from "../../constants/tokens";

export function PreviousItemRow({
  link = "/",
  externalLink = false,
  progressBar = true,
  currency = "",
  name = "",
  description = "",
  duration = "",
  rewards = "",
}) {
  const _getProjectImage = (projectToken) => {
    const {gnoken, limbo, mofi, blu, lanbx, cwl, kasu, booty} = tokens;

    switch (projectToken) {
      case gnoken:
        return GnomesIcon;
      case limbo:
        return LimboProjectIcon;
			case mofi:
        return MofiProjectIcon;
			case blu:
        return HumonProjectIcon;
			case lanbx:
        return LbProjectIcon;
		    case cwl:
        return CWLProjectIcon;
			case kasu:
        return CryptoPupsProjectIcon;
			case booty:
        return DegenProjectIcon;
      default:
        return GnomesIcon;
    }
  };

  const _getLogo = (projectToken) => {
    const {gnoken, limbo, mofi, blu, lanbx, cwl, kasu, booty} = tokens;

    switch (projectToken) {
      case gnoken:
        return GnokenIcon;
      case limbo:
        return LimboIcon;
			case mofi:
        return MofiIcon;
		case blu:
        return BluIcon;
			case lanbx:
        return LbIcon;
		case cwl:
			return CWLIcon;
			case kasu:
        return KasuIcon;
			case booty:
        return DegenIcon;
      default:
        return GnokenIcon;
    }
  };

  return externalLink ? (
    <a href={link}>
      <div className="previous-item hover-shape-border hover-shape-inner">
        <div className="previous-gaming list1Custom">
          <div className="previous-image">
            <img
              src={_getProjectImage(currency)}
              width="75"
              alt="previousImage"
            />
          </div>
          <div className="previous-price">
            <h4 className="mb-10">{name}</h4>
            <div className="dsc">{description}</div>
          </div>
        </div>
        <div className="previous-chaining list2Custom">
          <img src={_getLogo(currency)} width="50" alt="tokenImage" />
        </div>
        <div className="previous-EndsIn list3Custom">
          <span>{duration}</span>
        </div>
        <div className="previous-raise list4Custom">
          <span>{rewards}</span>
        </div>

        <span className="border-shadow shadow-1"></span>
        <span className="border-shadow shadow-2"></span>
        <span className="border-shadow shadow-3"></span>
        <span className="border-shadow shadow-4"></span>
        <span className="hover-shape-bg hover_shape1"></span>
        <span className="hover-shape-bg hover_shape2"></span>
        <span className="hover-shape-bg hover_shape3"></span>
      </div>
    </a>
  ) : (
    <Link to={link}>
      <div className="previous-item hover-shape-border hover-shape-inner">
        <div className="previous-gaming list1Custom">
          <div className="previous-image">
            <img
              src={_getProjectImage(currency)}
              width="75"
              alt="previousImage"
            />
          </div>
          <div className="previous-price">
            <h4 className="mb-10">{name}</h4>
            <div className="dsc">{description}</div>
          </div>
        </div>
        <div className="previous-chaining list2Custom">
          <img src={_getLogo(currency)} width="50" alt="tokenImage" />
        </div>
        <div className="previous-EndsIn list3Custom">
          <span>{duration}</span>
        </div>
        <div className="previous-raise list4Custom">
          <span>{rewards}</span>
        </div>
        <div className="previousProgress list5Custom">
          <span>Burn Pool</span>
        </div>

        <span className="border-shadow shadow-1"></span>
        <span className="border-shadow shadow-2"></span>
        <span className="border-shadow shadow-3"></span>
        <span className="border-shadow shadow-4"></span>
        <span className="hover-shape-bg hover_shape1"></span>
        <span className="hover-shape-bg hover_shape2"></span>
        <span className="hover-shape-bg hover_shape3"></span>
      </div>
    </Link>
  );
}
