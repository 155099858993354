const tokens = {
  gnoken: "gnokentokens",
  limbo: "token.limbo",
  mofi: "monstertoken",
	lanbx: "landbox.gm",
	  blu: "bludactokens",
	cwl: "metatoken.gm",
	kasu: "thekasutoken",
	booty: "degenpirates",
};

export default tokens;
