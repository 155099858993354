import React from "react";
import classNames from "classnames";

export function TierTable({
	className = "",
	tierColumn = 0,
	imageSrc,
	name,
	description,
	requirements = "",
	reward = "",
	promotion = "",
	poolWeight = 0,
	tierBlocks = 0,
}) {
	const tierColumnBody = classNames({
		TierV2_Col1: tierColumn === 1,
		TierV2_Col2: tierColumn === 2,
		TierV2_Col3: tierColumn === 3,
		TierV2_Col4: tierColumn === 4,
	});

	const tierColumnClass = classNames({
		TiedV2CollBody1: tierColumn === 1,
		TiedV2CollBody2: tierColumn === 2,
		TiedV2CollBody3: tierColumn === 3,
		TiedV2CollBody4: tierColumn === 4,
	});

	return (
		<div className={`TierV2_Col ${tierColumnBody} ${className}`}>
			<div className="tierV2ColHeadings">
				<span>
					<img src={imageSrc} alt="img" className="img-fluid" />
				</span>
				<h2>{name}</h2>
				<h3>{description}</h3>
			</div>

			<div className={`TiedV2CollBody ${tierColumnClass}`}>
				<ul>
					<li>
						<h4>Wallet Requirements</h4>
						<h5>{requirements}</h5>
					</li>
					<li>
						<h4>Reward Pool Creator</h4>
						<h5>{reward}</h5>
					</li>
					<li>
						<h4>Promotion</h4>
						<h5>{promotion}</h5>
					</li>
					<li className="m-0">
						<h4>Additional Pool Weight</h4>
						<h5>{poolWeight} %</h5>
					</li>
				</ul>

				{Array(tierBlocks)
					.fill(0)
					.map((tb, i) => {
						const spacingClass = classNames({
							"m-0": i === tierBlocks - 1,
						});

						return <div className={`bottomShape ${spacingClass}`}></div>;
					})}
			</div>
		</div>
	);
}
